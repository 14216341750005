import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/blindbox/order';
import * as apis from '../../../../api/client/blindbox/order';

export const infos = {

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': "创建福袋订单\n",
    'url': '/api/blindbox/order/create',
    'defaults': {
      bag_id: '',
      round: '',
      position: '',
      pay_choose: '',
      count: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'createValidate': {
    '__proto__': base.createValidate,
    'api': apis.createValidate,
    'desc': "创建验证订单\n",
    'url': '/api/blindbox/order/create-validate',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'pay': {
    '__proto__': base.pay,
    'api': apis.pay,
    'desc': " 支付福袋订单",
    'url': '/api/blindbox/order/pay',
    'defaults': {
      order_no: '',
      pay_type: '',
      wx_code: '',
      wx_return_url: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'payConch': {
    '__proto__': base.payConch,
    'api': apis.payConch,
    'desc': "纯贝壳支付\n",
    'url': '/api/blindbox/order/pay-conch',
    'defaults': {
      order_no: '',
      pass: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "订单列表\n",
    'url': '/api/blindbox/order/list',
    'defaults': {
      page: '',
      page_size: '',
      type: '',
      pay_status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "订单详情，结构基本同 list 接口\n",
    'url': '/api/blindbox/order/info',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'cancel': {
    '__proto__': base.cancel,
    'api': apis.cancel,
    'desc': " 取消订单",
    'url': '/api/blindbox/order/cancel',
    'defaults': {
      order_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'subBagChoose': {
    '__proto__': base.subBagChoose,
    'api': apis.subBagChoose,
    'desc': "二级福袋选择位置\n",
    'url': '/api/blindbox/order/sub-bag-choose',
    'defaults': {
      bag_id: '',
      sub_bag_id: '',
      positions: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'pushChooseJob': {
    '__proto__': base.pushChooseJob,
    'api': apis.pushChooseJob,
    'desc': "加入自动选择队列，超级福地部分退款完成后调用\n",
    'url': '/api/blindbox/order/push-choose-job',
    'defaults': {
      bag_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
