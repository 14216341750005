import * as utils from '@/utils/utils';
import * as constants from '@/config/constant';
import {infos as bbAdmin } from '@/powder/user/scms/blindbox/bag';
import {infos as client_order} from '@/powder/user/client/blindbox/order';
import {infos as client } from '@/powder/user/client/index';

export function getRoundInfo(bag) {
  const count_per_round = bag['total'] / bag['total_round'];
  return {
    count_per_round,
    cur_round: Math.floor((bag['total'] - bag['leftover']) / count_per_round),
  }
}

export async function waitSuperBagToFinished(bag_id) {
  if (constants.BagType === constants.BAG_TYPE_SUPER1) {
    let statusOK = false;
    // 超级福袋需要等待选择位置，状态变更为完成
    for (let i = 0; i < 10; i++) {
      await utils.sleep(1000);
      let info = await bbAdmin.info.make('args', bag_id);
      if (info.data.data.status === constants.BAG_STATUS_FINISH) {
        statusOK = true;
        break;
      }
    }
    utils.assert(statusOK, "超级福袋状态没有变更为完成");
  }
}

// once-more 未使用退款
export async function onceMoreRefund(infos_bag, pay_choose = constants.AllowConchMap.ALLOW_CONCH_WX) {
  let bag_create_args = {
    price: 200,
    allow_conch: pay_choose,
  };
  await client_order.create.make('once-more-no-use', bag_create_args, pay_choose);
  let bag_id = await infos_bag.create.lastResult.data.data.id;

  // way1: 终止福袋的方式，需要等待定时器
  // await infos_bag.abort.make('default', bag_id);

  // way2: 再购买剩余的全部，状态变为完成
  await client_order.pay.make('pay-all-get', bag_id, pay_choose);

  // 超级福袋等待完成
  await waitSuperBagToFinished(bag_id);
}

// 等待退款完成并验证订单信息
export async function waitRefund(order_id, pay_choose = constants.AllowConchMap.ALLOW_CONCH_WX) {
  let suc = false;
  for (let i = 0; i < 10; i++) {
    await utils.sleep(1000);
    let res = (await client_order.info.make('args', order_id)).data;
    if (pay_choose === constants.AllowConchMap.ALLOW_CONCH_PURE) {
      if (res.data.info.transaction_amount * 100 === res.data.info.conch_amount
        && res.data.info.partial_refund_amount === 0) {
          suc = true;
          break;
      }
    } else {
      if (res.data.info.transaction_amount * 100 === res.data.info.conch_amount
        && res.data.info.partial_refund_amount + 1 === res.data.info.amount) {
          suc = true;
          break;
      }
    }
  }
  utils.assert(suc, "纯贝壳支付，once-more 退款失败");
}

// 根据支付方式选择微信支付或者纯贝壳支付接口
export async function autoChoosePay(pay_choose, order_no) {
  if (pay_choose === constants.AllowConchMap.ALLOW_CONCH_PURE) {
    await client_order.payConch.make('args', order_no);
  } else {
    await client.testNotifyWX.make('args', order_no);
  }
}
