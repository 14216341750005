import * as utils from "@/utils/utils";
import { infos as client } from "@/powder/user/client/index";

// for admin
export function assertOK(ctx, info) {
  utils.assert(info.data.code === 200, "errno !== 200");
}

export function assertOKClient(ctx, info) {
  utils.assert(info.data.code === 1, "errno !== 1");
}

// for admin, 参数错误也用的此 code
export function assertParamEmpty(ctx, info) {
  utils.assert([10000].indexOf(info.data.code) !== -1, "参数非空验证失败");
}

export function assertParamError(ctx, info) {
  utils.assert([100004].indexOf(info.data.code) !== -1, "参数错误证失败");
}

// for admin
export function assertError(ctx, info) {
  utils.assert(info.data.code !== 200, "errno === 200");
}
